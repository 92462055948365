.SelectInput--Wrap {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.SelectInput--Input {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  appearance: none;
  margin: 0;
  border-radius: 0;
  border-bottom: 1px solid currentColor;
  font-size: 1em;
  text-align: right;
  padding: 2px 5px;
  padding-right: 25px;
  margin: 0 10px;
}

.SelectInput--Icon {
  height: 1em;
  width: 1em;
  position: absolute;
  right: 10px;
  pointer-events: none;
}
