.EmissionsModelWidget--Table {
  text-align: left;
  border-spacing: 10px 1px;
  margin-left: -10px;
}

.EmissionsModelWidget--Table--Row--Header {
  font-weight: 400;
  display: flex;
  align-items: center;
}

.EmissionsModelWidget--Table--Row--Header > * + * {
  margin-left: 0.3em;
}

.EmissionsModelWidget--Table--Row--Header--Name {
  font-weight: 600;
}

.EmissionsModelWidget--Table--Row--ValueTitle {
  text-align: right;
}

.EmissionsModelWidget--Table--Row--ValueCell {
  text-align: right;
}

.EmissionsModelWidget--Table--Row--ValueCellPlaceholder {
  display: block;
  border-bottom: 1px solid currentColor;
  height: 24px;
  width: 60px;
}

.EmissionsModelWidget--Table--Row--ValueCell[data-ismodified='true'] {
  color: rgb(43, 137, 226);
}

.EmissionsModelWidget--Table--Row--ValueCell[data-ismodified='true']:after {
  content: '*';
  position: absolute;
}

.EmissionsModelWidget--Table--Row--ValueCell[data-ismodified='true']
  .NumberInput {
  border-bottom-style: dashed;
}

.EmissionsModelWidget--Table--SeriesHeader {
  text-align: right;
  text-decoration: underline;
}

.EmissionsModelWidget--Table--FooterCell {
  text-align: right;
  padding: 0;
}

.EmissionsModelWidget--Table--FooterCell > * + * {
  margin-left: 5px;
}

.EmissionsModelWidget--Table--FooterButton svg {
  width: 1em;
  height: 1em;
}
