.MapLegend {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.MapLegend--Title {
  margin: 0;
  margin-bottom: 15px;
}

.MapLegend--Unit {
  font-weight: 500;
}

.MapLegend--Display > * + * {
  margin-top: 5px;
}

.MapLegend--ColourStop {
  display: flex;
}

.MapLegend--ColourBox {
  height: 25px;
  width: 25px;
  border: 1px solid black;
  border-radius: 2px;
  margin-right: 10px;
}

.MapLegend--Value {
  font-weight: 600;
}
