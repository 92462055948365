.Widgets--Wrap {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 2;
}

.Widgets--Box {
  position: relative;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
  padding: 30px 30px 30px 40px;
  max-width: 95%;
  margin: 0 auto 2.5vw auto;
  pointer-events: all;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
}

.Widgets--Box--Inner {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  overflow-x: scroll;
  padding-bottom: 10px;
}

.Widgets--Box--CloseButton {
  position: absolute;
  cursor: pointer;
  top: 12px;
  right: 15px;
  width: 20px;
  height: 20px;
  opacity: 0.8;
}

.Widgets--Box--CloseButton:hover,
.Widgets--Box--CloseButton:focus {
  opacity: 1;
  transform: scale(1.1);
}

.Widgets--Box--Column {
  flex-shrink: 0;
  min-width: 350px;
  display: flex;
  flex-direction: column;
}

.Widgets--Box--Column--Title {
  font-size: 15px;
  margin-top: 40px;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.Widgets--Box--Column + .Widgets--Box--Column {
  margin-left: 20px;
}

.Widgets--Title {
  position: absolute;
  font-weight: 300;
  font-size: 30px;
  margin: 0 0 20px 0;
}

.Widgets--Description {
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
  margin: 0;
  margin-top: 60px;
  padding-right: 20px;
  position: relative;
}
