// This is just to center the spinner

// Here is where the magic happens

$offset: 187;
$offset-small: 55;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: #212121;
  animation: dash $duration ease-in-out infinite,
    colors ($duration * 4) ease-in-out infinite;
}

.pathSmall {
  stroke-dasharray: $offset-small;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: #212121;
  animation: dashSmall $duration ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}

@keyframes dashSmall {
  0% {
    stroke-dashoffset: $offset-small;
  }
  50% {
    stroke-dashoffset: $offset-small/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset-small;
    transform: rotate(450deg);
  }
}
