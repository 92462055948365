.Map {
  width: 100vw;
  height: 100vh;
}

.Map--NavigationControl {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
}

.Map--Tooltip {
  position: absolute;
  margin: 8px;
  padding: 7px;
  background: rgba(255, 255, 255, 0.9);
  /* color: #fff; */
  /* border-radius: 3px; */
  max-width: 300px;
  font-size: 12px;
  z-index: 9;
  pointer-events: none;
  border-style: solid;
  border-width: 1px;
  border-left-width: 3px;
  border-color: #353535;
  border-radius: 3px;
}

.Map--Tooltip--Value {
  font-weight: 600;
}

.Map--Overlays {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Map--Overlays--Box {
  padding: 7px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 1);
  font-size: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: #353535;
  border-radius: 3px;
}

.Map--Overlays > * + * {
  margin-top: 15px;
}
