body {
  margin: 0;
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.85);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
  background: whitesmoke;
  padding: 15px;
}

* > * {
  box-sizing: border-box;
}

strong {
  font-weight: 600;
}

abbr[title] {
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
  border-bottom: 1px dotted currentColor;
}

a {
  color: inherit;
}

.button {
  appearance: none;
  display: inline-block;
  border: none;
  padding: 0.5em 1em;
  margin: 0;
  text-decoration: none;
  color: inherit;
  font-family: inherit;
  border-radius: 3px;
  background: whitesmoke;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  transition: background 150ms ease-in-out, transform 150ms ease;
}

.button:hover,
.button:focus {
  background: #eee;
}

.button:active {
  transform: scale(0.97);
}
